import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const atLeastOneRequired = (...controlNames: string[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const controls = controlNames
      .map((name) => control.get(name))
      .filter((control) => control !== null);
    const values = controls.map((control) => control?.value);

    if (values.every((value) => !value || value === '')) {
      controls.forEach(
        (control) => control?.setErrors({ atLeastOneRequired: true }),
      );
      return { atLeastOneRequired: true };
    } else {
      controls.forEach((control) => control?.setErrors(null));
      return null;
    }
  };
};
