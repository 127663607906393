import { AbstractControl, ValidatorFn } from '@angular/forms';

export function sameAsFormControl(field: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const group = control.parent;
    if (!group) {
      return null;
    }

    const fieldToCompare = group.get(field);
    return fieldToCompare?.value !== control.value
      ? { notEqualTo: field }
      : null;
  };
}
