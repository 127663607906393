import { FormControl } from '@angular/forms';

export interface ValidationResult {
  [key: string]: any;
}

export class PasswordValidator {
  public static strong(control: FormControl): ValidationResult | null {
    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const valid =
      hasNumber && hasUpper && hasLower && control.value.length >= 10;
    if (!valid) {
      // return what´s not valid
      return {
        strong: true,
      };
    }
    return null;
  }
}
